
<!-- ------------------------ Phone Field Component ------------------------ -->

<template>
  <section class="he-phone">

    <vue-tel-input-vuetify

      ref="phone"
      v-model="currentValue"
      placeholder=""

      :label="label || 'Phone number'"
      :description="description"

      :value="value"
      :rules="allRules"
      :only-countries="countries || ['ZA', 'AU', 'GB', 'US']"

      :dense="dense"
      :filled="filled"
      :outlined="outlined"
      :flat="flat"
      :solo="solo"

      @input="onInput"

    />

  </section>
</template>


<!-- ----------------------------------------------------------------------- -->


<script>

  /**
   * @file Phone.vue
   * @author Scheepers de Bruin
   * @module fields/Phone
   * @description Represents a phone number field component.
   *
   * @vue-prop {string} [label='Phone number'] Label to use for the field.
   * @vue-prop {string} [description] description to display with the field.
   *
   * @vue-prop {string} [value=''] Initial field value.
   * @vue-prop {array} [countries=['ZA', 'AU', 'GB', 'US']] Allowed country
   *                   codes.
   * @vue-prop {boolean} [required=false] Whether this field must have a value.
   * @vue-prop {array} [rules=[]] Validation rules.
   *
   * @vue-prop {object} [on] VJSF compatibility. Used to set field value in the
   *                    containing form object.
   *
   * @vue-computed {array} [allRules=[]] Aggregated array of custom rules and a
   *                                     rule for required as appropriate.
   *
   * @vue-prop {boolean} [dense='false'] vuetify dense style property.
   * @vue-prop {boolean} [filled='false'] vuetify filled style property.
   * @vue-prop {boolean} [outlined='false'] vuetify outlined style property.
   *
   * @vue-event {object} [validate] Fired upon validation.
   * @vue-event {object} [enter-key] Fired after enter key is pressed in the
   *                     number text field.
   */

  export default {

    inheritAttrs: true,

    /* ---------- Properties ---------- */

    props: [
      'label', 'description',
      'value', 'countries', 'required', 'rules',
      'dense', 'filled', 'outlined', 'flat', 'solo',
      'on'
    ],

    /* ----------- State ----------- */

    data() {
      return {
        currentValue: "",
        valid: true,
        country: undefined,
      }
    },

    computed: {

      /**
        * Combine provided rules with rules to:
        * verify number validity within the selected country.
        * enforce requiredness if required prop is provided.
        * @returns {array} consolidated array of vaildation functions.
      */
      allRules: function() {
        return (
          /* Built in rules */
          [
            () => this.valid || 'Invalid number for '.concat(this.country),
            this.required
              ? (value) => !!value || this.label.concat(' is required')
              : null
          ]
        ).concat(this.rules || []) /* Add provided rules */
         .filter((value) => !!value) /* Only keep non-null rules */
      }
    },

    /* ---------- Lifecycle hooks ---------- */

    /* Add keyup listener to the number input control. */
    mounted(){

      var thisObject = this

      setTimeout(
        function () {

          var id = thisObject.$refs.phone.$refs.input.$refs.input.id

          document.getElementById(id).addEventListener(
            'keyup',
            function(key){
              thisObject.onKeypress(key)
            }
          )
        }, 100
      )
    },

    /* ---------- Component methods ---------- */

    methods: {

      /**
        * Respond to input entered into the number field.
        *
        * @param {string} [international] phone number formatted in an
        *                 international form.
        * @param {object} [{valid, country}] Object containing current value and
        *                 validity.
        */
      onInput(international, { valid, country }) {

        var
          value = international
            .replace(/^0/g, country.dialCode)
            .replaceAll(' ', '')

        this.valid = valid
        this.country = country && country.name

        this.$emit('validate', { valid, value, country })

        if (this.on) this.on.input(value)
      },

      /**
        * Respond to the enter key being pressed while the text field is
        * focussed.
        * @param {object} [event] Vue event object.
        */
      onKeypress(event){
        if (event.code == 'Enter') {
          this.$emit('enter-key', { event, valid: this.valid })
        }
      }
    }
  }

</script>


<!-- ----------------------------------------------------------------------- -->


<style lang="scss">

  .he-phone{

    .vue-tel-input-vuetify {
      .country-code{

        width: 52px;

        fieldset{
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }

        .v-text-field--outlined.v-input--is-focused .v-input__apped-inner{
          color: var(--v-primary-base);
        }

        .v-text-field .v-select__selections .vti__flag {
          margin: 0 0 0 3px;
        }
      }
    }

    .vue-tel-input-vuetify > div:last-child fieldset{
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    .v-text-field--outlined.v-input--is-focused fieldset{
      border-style: solid;
    }

  }

</style>