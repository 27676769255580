
<!-- ------------------------ Schema Form Component ------------------------ -->


<template>
  <v-form
    class="he-schema-form"
    ref="form"
    v-model="valid"
  >

    <!-- Form generated from a JSON Schema -->

    <VJSF

      class="generated-form"
      v-if="schema && isReset"
      ref="jsonSchemaForm"

      :model="model"
      :options="options"
      :schema="computedSchema()"
      :value="model"

      @change="onChange"
      @keyup.native="onChange"
    >

      <!-- Warning slot for each element -->

      <template
        v-for="key in allProperties"
        slot-scope="context"
        :slot="key + '-after'"
      >
        <span :key="key">
          <v-list-item
            v-for="(warning, index) in getWarnings(
              context.value,
              context.schema
            )"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title>{{ warning }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </span>
      </template>

      <!-- Slot for custom phone number widget -->

      <template
        slot="custom-telephone"
        slot-scope="context"
      >
        <Phone
          placeholder=""
          :countries="
            context.schema['x-options'] && context.schema['x-options'].countries
              ? context.schema['x-options'].countries
              : ['ZA']
          "
          :description="context.schema.description"
          :label="context.label"
          :on="context.on"
          :required="context.required"
          :valid-characters-only="true"
          :value="context.value"
        />
      </template>

      <!-- Slot for custom Checkbox widget -->

      <template
        slot="custom-checkbox"
        slot-scope="context"
      >
        <Checkbox
          :description="context.schema.description"
          :label="context.label"
          :on="context.on"
          :required="context.required"
          :value="context.value"
        />
      </template>

      <!-- Slot for custom Textbox widget -->

      <template
        slot="custom-textbox"
        slot-scope="context"
      >
        <StrictTextbox
          v-model="context.value"
          :description="context.schema.description"
          :label="context.label"
          :on="context.on"
          :required="context.required"
          :rules="context.rules"
          :valid-keys="context.schema['x-props']['valid-keys']"
          :value="context.value"
        />
      </template>

      <!-- Slot for custom tree-select widget -->

      <template
        slot="custom-tree-select"
        slot-scope="context"
      >
        <HierarchySelect
          :label="context.label"
          :description="context.schema.description"
          :on="context.on"
          :required="context.required"
          :tree="context.schema['he-lookup'].tree"
          :value="context.value"
        />
      </template>
    </VJSF>

    <!-- Form Actions -->

    <footer class="he-schema-form-actions">
      <!-- Back button -->

      <v-btn
        v-if="hasBack()"
        class="he-schema-form-button"
        elevation="2"
        color="secondary"
        xLarge
        rounded
        @click="onBack"
      >
        <v-icon left>{{ icon.mdiArrowLeftThick }}</v-icon>
        Back
      </v-btn>

      <!-- Next button -->

      <v-btn
        v-if="hasNext()"
        class="he-schema-form-button"
        elevation="2"
        color="secondary"
        xLarge
        rounded
        @click="onNext"
      >
        Next
        <v-icon right>{{ icon.mdiArrowRightThick }}</v-icon>
      </v-btn>

      <!-- Submit button -->

      <v-btn
        v-if="hasSubmit()"
        class="he-schema-form-button"
        color="primary"
        xLarge
        rounded
        elevation="2"
        @click="onSubmit"
      >
        {{
          schema['he-metadata'] && schema['he-metadata']['submit']
            ? schema['he-metadata']['submit']
            : 'Submit'
        }}
        <v-icon right>{{ icon.mdiCheckBold }}</v-icon>
      </v-btn>
    </footer>
  </v-form>
</template>


<!-- ----------------------------------------------------------------------- -->


<script>
/**
 * @file ShemaForm.vue
 * @author Scheepers de Bruin
 * @module forms/SchemaForm
 * @description Represents a form generated from a Schema.
 *
 * @vue-prop {object} [schema] required used to generate the dynamic form.
 * @vue-prop {object} [errors={}] errors to set on form elements.
 * @vue-prop {boolean} [wizard=false] display the form as a wizard.
 *
 * @vue-data {object} [model] form data model.
 * @vue-data {boolean} [valid] form data model.
 * @vue-data {object} [options] options for third party form renderer.
 * @vue-data {array} [tabs] array of tab labels.
 * @vue-data {array} [panels] array of tab panels.
 * @vue-data {array} [allProperties] form data model.
 *
 * @vue-computed {object} [computedSchema] Schema with custom component
 *                        displays added.
 * @vue-computed {boolean} [hasNext] Whether the next button is visible.
 * @vue-computed {boolean} [hasBack] Whether the back button is visible.
 *
 * @vue-event {object} change - Emit values changed within the form.
 * @vue-event {object} submit - Emit from values upon successful validation.
 */

/* Dpendencies */
import Vue from 'vue'
import VJSF from '@koumoul/vjsf'
import Ajv from 'ajv'
import Phone from '@/components/fields/Phone'
import Checkbox from '@/components/fields/Checkbox'
import StrictTextbox from '@/components/fields/StrictTextbox'
import HierarchySelect from '@/components/fields/HierarchySelect'
import { detailedDiff } from 'deep-object-diff';

/* Mixins */
import Validation from './mixins/Validation.mixin.js'
import traverse from '../mixins/data/traverse.mixin.js'
import copy from '../mixins/data/copy.mixin.js'

/* Styles */
import '@koumoul/vjsf/dist/main.css'

/* Icons */
import {
  mdiArrowLeftThick,
  mdiArrowRightThick,
  mdiCheckBold
} from '@mdi/js'


export default {

  name: 'HeSchemaForm',

  components: {
    VJSF,
    Phone,
    HierarchySelect,
    Checkbox,
    StrictTextbox
  },

  mixins: [traverse, copy, Validation],

  /* ----------- State ----------- */

  props: {
    schema: { type: Object, required: true },
    errors: { type: Object },
    wizard: { type: Boolean },
    language: { type: Boolean },
  },

  data() {
    return {
      model: {},
      valid: false,
      isReset: true,
      validationAttempted: false,
      tabs: null,
      panels: null,
      allProperties: {}
    }
  },

  /* ----------- Computed ----------- */

  computed: {

    icon() {
      return {
        mdiArrowLeftThick,
        mdiArrowRightThick,
        mdiCheckBold
      }
    },

    isWizard() {
      return this.wizard != null
    }
  },

  /* ---------- Lifecycle hooks ---------- */

  /**
   * Hooked to initialise validator and attach to the form.
   */
  created() {
    this.options = {
      ajv: new Ajv(),
      debug: true,
      disableAll: false,
      rules: {}
    }
  },

  /**
   * Hooked to collect tab elements.
   */
  mounted() {
    this.attachTabs()
    this.oldModel = {}
  },

  /**
   * Hooked to collect tab elements.
   */
  updated() {
    if (!this.tabs) this.attachTabs()
  },

  /* ---------- Component methods ---------- */

  methods: {

    /**
     * Calculates whether a next button should be shown.
     * @returns {boolean}
     */
    hasNext() {
      return this.isWizard && this.getActiveTab() < this.tabCount - 1
    },

    /**
     * Calculates whether a back button should be shown.
     * @returns {boolean}
     */
    hasBack() {
      return this.isWizard && this.getActiveTab() > 0
    },

    /**
     * Calculates whether a submit button should be shown.
     * @returns {boolean}
     */
    hasSubmit() {

      var
        lastTab = this.getActiveTab() == this.tabCount - 1,
        isWizard = this.isWizard && this.tabs

      return (
        (isWizard && (lastTab || this.validationAttempted)) ||
        !isWizard
      )
    },

    /**
     * Get a reference to the displayed tabs in the form.
     */
    attachTabs() {
      /* Populate tab properties if wizard display */
      if (this.isWizard) {
        try {
          this.tabs = this.$refs.jsonSchemaForm
            .$children[0].$children[0]
            .$data.items
          this.tabCount = this.tabs.length

          this.panels = []
          var panels = this.$refs.jsonSchemaForm.$children[0].$children[1]
            .$children

          panels.forEach((panel, index) => {
            this.panels[index] = panel.$children[0].$children[0].$children
          })
        } catch (error) {
          this.tabs = false
          this.tabCount = 0
        }
      }
    },

    /**
     * Computes a complete schema by adding server error messages and attributes
     * to show mobile number pads.
     * @returns {object} The computed schema.
     */
    computedSchema() {

      var
        schema = this.copy(this.schema),
        rules = {}

      this.traverse(
        schema,

        ({ node: field, key: fieldName, path: fieldPath, }) => {

          // Keep track of changes to erroneous fields
          let
            fieldPathString = `/${fieldPath.join('/')}`,
            fieldErrors = this.errors[fieldPathString]

          if (fieldErrors) this.setFieldRules(
            field,
            fieldName,
            fieldPathString,
            fieldErrors,
            rules
          )

          /* Collect property names for warning slots */
          if (fieldName) this.allProperties[fieldName] = fieldName

          /* Wrap radio buttons to make required attribute works */
          if (field.type === 'boolean') {
            field['x-display'] = 'custom-checkbox'
          }

          /* Add properties for mobile numberpads */
          if (field.type === 'number') {
            field['x-props'] = Object.assign(
              field['x-props'] || {},
              {
                inputmode: 'numeric',
                pattern: '[0-9]*'
              }
            )
          }
        },

        {
          getChildren: field =>
            field.properties ||
            field.allOf ||
            field.anyOf ||
            field.oneOf ||
            [],
          context: this
        }
      )

      Vue.nextTick(
        () => {
          if (Object.keys(this.errors).length) {
            this.$refs.jsonSchemaForm.validate(true)
            this.focusErrorTab()
          }
        }
      )

      this.options.rules = rules

      return schema
    },

    /**
     * Gets the active Tab index.
     * @returns {int} [tabIndex=0]
     */
    getActiveTab: function () {
      return this.tabs ? this.tabs.findIndex(tab => tab.isActive) : 0
    },

    /**
     * Computes warning(s) for a field based on its schema and value.
     * @param {string} [value] value to evaluate agains warning patterns.
     * @param {object} [schema] field schema to evaluate against.
     */
    getWarnings(value, schema) {

      if (value != undefined && value != null && schema['x-warnings']) {

        var texts = Object.keys(schema['x-warnings']).reduce(
          (warnings, pattern) => {
            if (new RegExp(pattern).test(value)) {
              warnings.push(schema['x-warnings'][pattern])
            }
            return warnings
          },
          []
        )

        return texts
      }
    },

    /**
     * React to a value in the form being changed.
     * Fires a change event.
     */
    onChange() {
      this.$emit('change', detailedDiff(this.oldModel, this.model))
      this.oldModel = this.copy(this.model)
    },

    /**
     * React to Next click.
     * Runs validation for each element on the tab panel before jumping to the
     * next if there are no errors.
     */
    onNext() {

      var tabValid = true

      this.traverse(
        { items: this.panels[this.getActiveTab()] },

        ({ node }) => {
          if (node.validate) {
            tabValid &= node.validate(true)
          }
        },

        {
          getChildren: element => element.$children || element.items
        }
      )

      if (tabValid) {
        this.tabs[this.getActiveTab() + 1].$el.dispatchEvent(new Event('click'))
      }
    },

    /**
     * React to Back click.
     * Jumps to the previous tab panel.
     */
    onBack() {
      this.tabs[this.getActiveTab() - 1].$el.dispatchEvent(new Event('click'))
    },

    /**
     * React to Submit click. Clones current data state uses to construct
     * submission error validators so that submission errors may be displayed
     * inline.
     * Fires a submit event if the form is valid.
     */
    onSubmit() {

      this.lastModel = this.copy(this.model)
      this.validationAttempted = true

      if (this.$refs.form.validate(true)) {
        this.$emit('submit', this.model)
      } else if (this.tabs) {
        Vue.nextTick(
          () => { this.focusErrorTab() }
        )
      }
    },

    /**
     * Resets the form.
     */
    reset(model) {
      this.isReset = false
      this.validationAttempted = false
      this.model = model || {}
      Vue.nextTick(
        () => {
          this.attachTabs()
          this.validationAttempted = false
          this.isReset = true
        }
      )
    },

    /**
     * Focusses the first tab containing errors.
     */
    focusErrorTab() {
      if (this.tabs) {
        let tabs = this.tabs
        Vue.nextTick(
          () => {
            tabs
              .slice()
              .reverse()
              .forEach(
                tab => {
                  if (tab.$el.innerHTML.includes('error')) {
                    tab.$el.dispatchEvent(new Event('click'))
                  }
                }
              )
          }
        )
      }
    }
  }
}
</script>


<!-- ----------------------------------------------------------------------- -->


<style lang="scss">

  .he-schema-form {

    .v-tabs {
      display: flex;
      flex-direction: column-reverse;

      .v-tabs-bar {
        margin: 0 0 2em;
        height: 2.5em;

        .v-tabs-bar__content {
          justify-content: center;
        }

        .v-tab {
          background: var(--v-background);
          border: 1px solid var(--v-secondary);
          border-radius: 2em;
          overflow: hidden;
          color: transparent !important;
          flex: 0 0 auto;
          justify-content: space-around;
          width: 3em;
          margin: 0 1em;
          min-width: unset;

          span {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            &.error--text {
              background: var(--v-background);
              color: transparent !important;

              &:after {
                font-size: 2em;
                font-weight: bold;
                color: var(--v-error);
                content: '!';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                padding-top: 0.125em;
                font-family: serif;
              }
            }
          }
        }

        .v-tab--active {
          background: var(--v-secondary);

          span.error--text {
            background: var(--v-error);

            &:after {
              color: var(--v-background);
            }
          }
        }

        .v-tabs-slider-wrapper {
          display: none;
        }
      }
    }

    .generated-form{

      .tab-heading > .row.ma-0 > .col.pa-0:first-child{
        margin-bottom: 1em;
        font-size: 22px !important;
      }

      .section-heading .v-input--radio-group__input > .v-label,
      .subtitle-1{
        margin-bottom: 1em;
        font-size: 18px !important;
        font-weight: 500 !important;
      }
    }

    .vjsf-property {
      margin-bottom: 0.5em;
    }

    .v-input {
      margin: 0.5em 0;
      padding: 0;
    }

    .he-schema-form-actions {
      margin: 3em 0;
      text-align: center;
    }

    .he-schema-form-button {
      margin: 0.5em;
      font-weight: bold;
    }
  }
</style>