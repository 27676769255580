export default {

  methods: {
    /**
     * Traverse a (sub)node and perform a callback function on each
     * node.
     * @param {object} [node] starting node to traverse.
     * @param {args} [object] traversal arguments TODO.
     */
    traverse: function (node, callback, args = {}) {

      if (node) {

        var

          // Traversal metadata
          { context, key, parent } = args,
          appendPath = parent && !(parent.allOf || parent.anyOf || parent.oneOf),
          // path = args.path
          //   ? [...args.path, key]
          //   : [],
          path = args.path
            ? (appendPath ? [...args.path, key] : args.path)
            : [],

          // Get children
          getChildren = args.getChildren || ((node) => node.children),
          children = getChildren(node) || [],

          // Get child keys (or indexes in the case of arrays)
          childKeys = Object.keys(
            Object.assign(
              {},
              children
            )
          ) || [],

          // Arguments for callback function
          callbackArgs = {
            context,
            key,
            parent,
            path,
            node,
            childKeys,
            children
          }

        // Traverse children
        childKeys.forEach(
          (childKey) => {

            this.traverse(
              children[childKey],
              callback,
              {
                context,
                key: childKey,
                parent: node,
                path,
                getChildren
              }
            )
          }
        )

        // Perform callback operation
        context
          ? callback.call(context, callbackArgs)
          : callback(callbackArgs)
      }
    }
  }
}
