
<!-- -------------------------- Strict  Component -------------------------- -->


<template>

  <v-text-field
    class="he-textbox"
    :label="label"
    :description="description"
    :value="value"
    :rules="rules"
    :dense="dense"
    :filled="filled"
    :outlined="outlined"
    @keydown="onKeyDown"
    @change="onChange"
  />

</template>


<!-- ----------------------------------------------------------------------- -->


<script>
/**
 * @file StrictTextbox.vue
 * @author Scheepers de Bruin
 * @module fields/StrictTextbox
 * @description Represents textbox field component that supports removing
 *              invalid characters.
 *
 * @vue-prop {string} [label] Label to use for the field.
 * @vue-prop {string} [description] description to display with the field.
 *
 * @vue-prop {string} [value=false] Initial field value.
 * @vue-prop {boolean} [required=false] Whether this field must have a value.
 * @vue-prop {array} [rules=[]] Validation rules.
 *
 * @vue-prop {object} [on] VJSF compatibility. Used to set field value in the
 *                    containing form object.
 *
 * @vue-prop {boolean} [dense='false'] vuetify dense style property.
 * @vue-prop {boolean} [filled='false'] vuetify filled style property.
 * @vue-prop {boolean} [outlined='false'] vuetify outlined style property.
 *
 * @vue-computed {string} [REQUIRED_ERROR] Error message to display when
 *                        required checkbox is left unchecked.
 */

export default {
  inheritAttrs: true,

  /* ---------- Properties ---------- */

  props: [
    'label',
    'description',
    'value',
    'required',
    'rules',
    'valid-keys',
    'dense',
    'filled',
    'outlined',
    'on'
  ],

  /* ----------- State ----------- */

  computed: {
    REQUIRED_ERROR: () => 'This information is required',
    NAVIGATION_KEYS: () => [8, 27, 46, 37, 39]
  },

  /* ---------- Lifecycle hooks ---------- */

  mounted() {
    this.on.input(this.value)
    this.validKeyTester = this.validKeys
      ? new RegExp(this.validKeys)
      : false
  },

  /* ---------- Component methods ---------- */

  methods: {

    /**
     * Reacts to a value change. Alerts the parent component of a value change.
     * @param {string} [value] New value of the field.
     */
    onChange(value){
      if (this.on) this.on.input(value)
      this.$emit('change', value)
    },

    /**
     * Reacts to a change in the Textbox value by removing invalid characters
     * and altering its parent through the "on" property object, if supplied.
     * @param {boolean} value new checkbox field value [true | false]
     * @see https://github.com/koumoul-dev/vuetify-jsonschema-form/tree/master/doc/components/wrappers
     */
    onKeyDown(event) {
      if (
        this.validKeyTester &&
        !this.NAVIGATION_KEYS.includes(event.keyCode) &&
        !this.validKeyTester.test(event.key)
      ) {
        event.preventDefault()
        event.stopPropagation()
      }
    }
  }
}
</script>