
/**
 * @file Validation.mixin.js
 * @author Scheepers de Bruin
 * @module views/mixins/Schema
 * @description Rule loading and application utilities.
 */

import pointer from 'jsonpointer'

export default {

	data: function () {
		return {
			validator: null,
			lastModel: {},
		}
	},

	methods: {

		/**
		 * Sets form errors on a field.
		 * @param {sting} [key] key of the field.
		 * @param {object} [field] schema definition of the field.
		 * @param {array} [errors] array of field errors:
		 *  [
		 *    {
		 *      fields: ['/path/to/field/1', '/path/to/field/2' ... ],
		 *      message: 'Error message'
		 *    }
		 *  ]
		 * TODO: update docs
		 */
		setFieldRules(field, fieldName, fieldPath, fieldErrors, rules) {

			let
				fieldValue = pointer.get(
					this.lastModel,
					fieldPath
				)

			fieldErrors.forEach(
				(error, errorIndex) => {

					let
						monitorParams = {
							field,
							fieldName,
							fieldPath,
							fieldValue,
							error,
							errorIndex,
							rules
						},

						validatorKey = error.fields.length > 1
							? this.setCompoundRule(monitorParams)
							: this.setSimpleRule(monitorParams)

					field['x-rules'] = field['x-rules'] || []
					field['x-rules'].push(validatorKey)
				}
			)
		},

		setCompoundRule({ field, fieldName, fieldValue, error, rules }) {

			let
				aggregatedRuleKey = error.fields.join(' & '),
				fieldRuleKey = `${fieldName}-${aggregatedRuleKey}`

			// Set up individual field validator
			rules[fieldRuleKey] = this.getValueComparator(
				field.format,
				fieldValue,
				error.message
			)

			/*
				Set up aggregated validator if it hasn't been created by another
				target field
			*/
			if (!rules[aggregatedRuleKey]) {

				let
					formComponent = this,
					scopedPointer = pointer

				rules[aggregatedRuleKey] = () => {

					// Step through each individual validator
					let validation = error.fields.reduce(

						(valid, targetPath) => {

							let
								targetFieldName = targetPath.match(/[^/]+$/)[0],
								value = scopedPointer.get(formComponent.model, targetPath),
								dependentValidation = rules[
									`${targetFieldName}-${aggregatedRuleKey}`
								](value)

							if (dependentValidation === true) valid = true

							return valid
						},

						error.message
					)

					formComponent.cascadeValidation()

					return validation
				}
			}

			return aggregatedRuleKey
		},

		// TODO: doc
		setSimpleRule({ field, fieldName, fieldValue, error, errorIndex, rules }) {

			let
				validatorKey = `${fieldName}-${errorIndex}`

			rules[validatorKey] = this.getValueComparator(
				field.format,
				fieldValue,
				error.message
			)

			return validatorKey
		},

		/**
		 * TODO: doc
		 */
		getValueComparator(format, fieldValue, errorMessage) {

			var
				equals = format == 'date'
					? (a, b) => new Date(a).toDateString() == new Date(b).toDateString()
					: (a, b) => a == b

			return (value) => equals(value, fieldValue) ? errorMessage : true
		},

		/**
		 * TODO: doc
		 */
		cascadeValidation() {
			if (!this.cascading) {
				this.cascading = true

				this.traverse(
					{ items: this.$refs.jsonSchemaForm },
					({ node }) => { if (node.validate) node.validate(true) },
					{
						getChildren: element => element.$children || element.items
					}
				)

				this.cascading = false
			}
		}
	}
}