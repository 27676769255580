<!-- --------------------- Hierarchy Select Component ---------------------- -->


<template>

  <v-input
    ref="wrapper"
    class="hierarchy-select"
    :error-messages="error"
  >

    <!-- Autocomplete/select for each level -->

    <v-autocomplete v-for="(value, index) in controls()"

      :return-object="true"

      :label="index==0 ? label : ''"
      :value="valuePath[index]"

      :placeholder="index > 0 ? 'Select' : null"
      :items="value.children"
      :key="index"
      :ref="index"
      :error="!!error"

      @input="(value) => onSelect(value, index)"
      @blur="() => onBlur(index)"
      @focus="() => onFocus(index)"
    />

    <!-- TODO: description popup -->

  </v-input>

</template>


<!-- ----------------------------------------------------------------------- -->


<script>

/**
 * @file HierarchySelect.vue
 * @author Scheepers de Bruin
 * @module fields/HierarchySelect
 * @description Represents a hierarchical value selection component.
 *
 * @vue-prop {string} [label] Field lable to be displayed.
 * @vue-prop {string} [description] Field description to be displayed. TODO
 * @vue-prop {string} [value] Deepest selection value.
 * @vue-prop {object} [tree] Hierarchical option tree.
 * @vue-prop {boolean} [required] WHether this field must have a value.
 * @vue-prop {object} [rules] Validation function dictionary.
 * @vue-prop {object} [on] VJSF compatibility. Used to set field value in the
 *                    containing form object.
 *
 * @vue-data {array} [valuePath] Array of selected values.
 * @vue-data {array} [lookup] Value keyed node lookup dictionary.
 * @vue-data {string} [error] error message to be displayed.
 * @vue-data {object} [validationTimeout] timeout to trigger validation if any
 *                    of the fields lose focus.
 *
 * @vue-computed {array} Array of controls to render based on the value path.
 */


/* Mixins */
import traverse from "../mixins/data/traverse.mixin.js"


export default {

  mixins: [traverse],

  /* ---------- Properties ---------- */

  props: {

    label: {},
    description: {},
    value: {},

    tree: {},
    required: {},
    rules: {
      default: () => []
    },
    on: {},
  },

  /* ----------- State ----------- */

  data: function () {
    return {
      valuePath: [],
      lookup: {},
      error: null,
    }
  },

  watch: {
    value: function (newValue) {

      console.log(
        {
          new: 'new value',
          newValue
        }
      )

      this.valuePath = []

      if (!newValue) {

        this.valuePath = []
        this.errorMessage = null
        this.error = null
        clearTimeout(this.validationTimeout)
        this.resetting = true

      } else {

        // Find the value within the tree
        this.traverse(

          { children: this.tree },

          ({ path, node }) => {
            if (node.value == newValue) {

              /*
                Step through the path from root to the value, setting the
                value for each select.
              */
              let subTree = { children: this.tree }
              path.forEach(
                (childIndex, pathIndex) => {
                  subTree =
                    this.valuePath[pathIndex] =
                    subTree.children[childIndex]
                }
              )
            }
          }
        )
      }
    }
  },

  /* ---------- Lifecycle hooks ---------- */

  /**
   * Hooked to build lookup dictionary, build the value path and attaches the
   * validation function to the wrapper for from validation.
   */
  mounted() {
    var thisObject = this
    this.$refs.wrapper.validate = (showError) => thisObject.validate(showError)
  },

  /* ---------- Component methods ---------- */

  methods: {

    /**
     * React to a value selected on one of the fields.
     * @param {string} [value] the selected value.
     * @param {int} [depth] the depth of the selection field.
     */
    onSelect(value, depth) {

      this.valuePath = this.valuePath.slice(0, depth)
      this.error = null

      if (value) this.valuePath.push(value)

      this.$emit('input', value)
      if (this.on) {
        this.on.input(value)
      }
    },

    /**
     * Sets a timeOut to trigger field validation when any field loses focus.
     */
    onBlur() {
      clearTimeout(this.validationTimeout)
      this.validationTimeout = setTimeout(
        () => this.validate(true), 10
      )
    },

    /**
     * Cancels field validation timeout when any other field gains focus.
     */
    onFocus() {
      clearTimeout(this.validationTimeout)
    },

    controls() {
      return [
        { children: this.tree },
        ...this.valuePath
      ].filter((node) => !!node.children)
    },

    /**
     * Validates the component.
     */
    validate(showErrors) {

      let error = null

      if (
        this.required && (
          !this.valuePath.length
          || this.valuePath[this.valuePath.length-1].children
        )
      ){
        error = 'This information is incomplete'
      } else {

        for (let rule of this.rules) {
          let invalid = rule(this.value)
          if (typeof invalid == 'string') {
            error = invalid
          }
        }
      }

      this.error = showErrors ? error : null

      return !error
    }
  }
}

</script>


<!-- ----------------------------------------------------------------------- -->


<style lang="scss">

  .hierarchy-select {

    & > .v-input__control{

      & > .v-input__slot{

        margin: 0;
        flex-wrap: wrap;

        .v-input{
          min-width: 100%;
        }

        .error--text .v-select__slot input,
        .error--text .v-select__slot input::placeholder {
          color: var(--v-error);
        }

        .v-input__slot {
          margin: 0;
        }

        .v-text-field{
          margin: 0;
          padding: 0;
        }

        .v-text-field__details{
          display: none;
        }
      }

      & > .v-messages {
        padding-top: 8px;
      }
    }
  }

</style>