export default {
  methods: {
    /**
     * Copies a data object.
     * @param {object} [object] data object to copy.
     * @returns {object} copied data object.
     */
    copy(object){
      return JSON.parse(JSON.stringify(object))
    }
  }
}