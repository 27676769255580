
<!-- ------------------------- Checkbox Component -------------------------- -->


<template>

  <v-checkbox

    class="he-checkbox"

    :label="label"
    :description="description"

    :input-value="value"
    :true-value="true"
    :false-value="false"

    :rules="required ? [value => !!value || REQUIRED_ERROR] : []"

    :dense="dense"
    :filled="filled"
    :outlined="outlined"

    @change="onChange"

  />

</template>


<!-- ----------------------------------------------------------------------- -->


<script>

  /**
   * @file Checkbox.vue
   * @author Scheepers de Bruin
   * @module fields/Checkbox
   * @description Represents checkbox field component that supports being
   *              required.
   *
   * @vue-prop {string} [label] Label to use for the field.
   * @vue-prop {string} [description] description to display with the field.
   *
   * @vue-prop {string} [value=false] Initial field value.
   * @vue-prop {boolean} [required=false] Whether this field must have a value.
   * @vue-prop {array} [rules=[]] Validation rules.
   *
   * @vue-prop {object} [on] VJSF compatibility. Used to set field value in the
   *                    containing form object.
   *
   * @vue-prop {boolean} [dense='false'] vuetify dense style property.
   * @vue-prop {boolean} [filled='false'] vuetify filled style property.
   * @vue-prop {boolean} [outlined='false'] vuetify outlined style property.
   *
   * @vue-computed {string} [REQUIRED_ERROR] Error message to display when
   *                        required checkbox is left unchecked.
   */

  export default {

    inheritAttrs: true,

    /* ---------- Properties ---------- */

    props: [
      'label', 'description',
      'value', 'required', 'rules',
      'dense', 'filled', 'outlined', 'flat', 'solo',
      'on'
    ],

    /* ----------- State ----------- */

    computed: {
      REQUIRED_ERROR: () => 'This information is required'
    },

    /* ---------- Lifecycle hooks ---------- */

    mounted(){
      if (this.on) this.on.input(this.value)
    },

    /* ---------- Component methods ---------- */

    methods: {

      /**
       * Reacts to a change in the checkbox value by altering its parent through
       * the "on" property object, if supplied.
       * @param {boolean} value new checkbox field value [true | false]
       * @see https://github.com/koumoul-dev/vuetify-jsonschema-form/tree/master/doc/components/wrappers
       */
      onChange(value){
        if (this.on) this.on.input(value)
      }
    }
  }

</script>